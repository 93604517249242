@import url('https://fonts.googleapis.com/css?family=League+Spartan');

.App {
  text-align: center;
  font-family: 'League Spartan', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial';
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2E2D4D;
  min-height: 95vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 2.5vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-footer {
  background-color: #2E2D4D;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2% 40%;
}

.App-link {
  color: #61dafb;
}

.Landing-Page {
  /* font-size: 7rem; */
  font-size: calc(0.5rem + 15vmin);
  width: 100%;
  position: absolute;
  bottom: 35%;
}

.Byline {
  font-size: calc(0.5rem + 4vmin);
  width: 100%;
  position: absolute;
  bottom: 38%;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  /* justify-content: space-between; */
  font-size: calc(10px + 2vmin);
  color: white;
  min-width: 75%;
  max-width: 1000px;
  /* padding-right: 5%; */
  
  text-align: center;

}

a:link{
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
  text-decoration: none;
}

h1{
  padding: 10vh 10vw;
}

p{
  font-size: calc(10px + 1vmin);
  text-align: justify;
  white-space: pre-wrap;
  min-width: 300px;
}

.header-link {
  /* border-bottom: 5vh solid red;
  border-left: 2.5vw solid transparent;
  border-right: 2.5vw solid transparent;
  height: 0; */
  width: 20vw;
  /* padding-top: 2vh; */
  border-radius: 10vw 10vw 0 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: inline; */
  margin: 2vh 0.25vw 0 0.25vw;
  border-top: 1vh solid transparent;
  border-bottom: 0.25vw solid transparent;
  position:relative;
  
}

/* .header-inner{
  position: absolute;
  top: -8%;
  left: 1%;
  z-index: 1;
  width: 98%;
  border-radius: 10vw 10vw 0 0;
  text-align: center;
  align-items: center;
  justify-content: center;
} */


.header-link:hover{
  border-top: 1.5vh solid transparent;
  margin-top: 0.5vh;
  /* color: white; */
  text-decoration: underline;
  text-align: center;
}

.home {
  background-color: #36393e;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about {
  background-color: #424549;
}

.game-design {
  background-color: #D66853;
}

.coding {
  background-color: #26A96C;
}

.art {
  background-color: #4BA3C3;
}

.carousel-box {
  position: relative;
  display: inline-block;
  margin: 10px;
  padding-bottom: 27px;
  width: 75vw;;
}

.carousel-name {
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-color: yellow; */
  color: black;
  padding: 5px;
  font-size: 30px;
  width: 300px;
  text-align: left;
  padding-left: 15px;
  z-index: 30;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-top: 2px solid white;
}

/* .carousel-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid white;
} */

.triangle-art {
  width: 0;
  height: 0;
  border-top: 27.5px solid transparent;
  border-right: 50px solid #4BA3C3;
  border-bottom: 27.5px solid transparent;
  position: absolute;
  bottom: 2px;
  right: 300px;
  z-index: 20;
}

.triangle-game-design {
  width: 0;
  height: 0;
  border-top: 27.5px solid transparent;
  border-right: 50px solid #D66853;
  border-bottom: 27.5px solid transparent;
  position: absolute;
  bottom: 2px;
  right: 300px;
  z-index: 20;
}

.triangle-coding {
  width: 0;
  height: 0;
  border-top: 27.5px solid transparent;
  border-right: 50px solid #26A96C;
  border-bottom: 27.5px solid transparent;
  position: absolute;
  bottom: 2px;
  right: 300px;
  z-index: 20;
}

.triangle-background {
  width: 0;
  height: 0;
  border-top: 29.5px solid transparent;
  border-right: 54px solid white;
  border-bottom: 29.5px solid transparent;
  position: absolute;
  bottom: 0;
  right: 300px;
  z-index: 15;
}

.custom-carousel .carousel-control-prev, .custom-carousel .carousel-control-next {
  position: absolute;
  width: auto;
  padding: 5px;
}

.custom-carousel .carousel-control-prev {
  border-right: 2px solid white;
}

.custom-carousel .carousel-control-next {
  border-left: 2px solid white;
}

.custom-carousel {
  border: white 2px solid;
  padding: 10px 50px;
}

.carousel-item {
  position: relative;
  height: 100%;
  /* width: 100%; */
  /* object-fit: cover; */
  /* border: 2px solid white; */
}

.overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(124, 122, 122, 0.4);
  /* background-color: rgba(255,255,255,0.5); */
  /* background-color: red; */
  z-index: 10;
  color: white;
  transition: .5s ease;
  opacity: 0;
  text-align: center;
  padding: 45% 0;
}

.hover-image{
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-image:hover .overlay{
  opacity: 1;

}

.page {
  padding-bottom: 23vh;
  /* border-top: 2px solid white; */
}

.page-border {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.page-border-inner{
  height: 2px;
  width: 15vw;
}

.page-border-outer{
  height: 2px;
  background-color: white;
  width: 12.5vw;
}

.white{
  background-color: white;
}

.light-grey{
  background-color: lightgray;
}


.split{
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap-reverse;
}

.about-div{
  width: 40vw;
  min-width: 350px;
  margin-top: 5%;
  padding: 10px;
}

.profile-image{
  max-width: 10vw;
  max-height: 10vw;
  min-width: 200px;
  min-height: 200px;
  border-radius: 100%;
  margin-bottom: 30px;
}

.skill-image{
  width: 80px;
  height: 80px;
  margin: 5px;
}


.link-label:link {
  color: black;
  text-decoration: none;
}

.link-label:visited{
  color: black;
  text-decoration: none;
}

.link-img{
  width: 50px;
  padding: 0 5px 4px 0px;
}

.modal-image{
  max-width: 100%;
  max-height: 100%;
  min-width: 300px;
  object-fit: cover;
  border: 2px solid white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 920px) {
  .about-div{
    width: 100vw;
    margin-top: 5%;
    padding: 10px 10%;
  }
  .hello{
    display: none;
  }
}

@media (max-width: 450px) {
  .carousel-box{
    display: none;
  }
}
